import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2811543174/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`A guide for card enrollment with the iOS SDK `}<a style={{
        "borderBottom": "2px solid #0048ff"
      }} className="improve-docs" href="/select/sdks/ios/guide-v1">{`v1`}</a>{` `}<a style={{
        "marginRight": "auto",
        "color": "#111"
      }} className="improve-docs" href="/select/sdks/ios/guide-v2">{`v2`}</a></h1>
    <p>{`Please take the following steps to integrate and configure the SDK for your Loyalty use case application.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note: If an example project helps with your SDK integration & configuration, please check our `}<a parentName="p" {...{
          "href": "https://github.com/Enigmatic-Smile/fidel-ios/"
        }}>{`GitHub repository`}</a>{`.`}</p>
    </blockquote>
    <h3>{`1. Set up your Fidel API account & your Transaction Select program`}</h3>
    <p>{`To get started, you'll need a Fidel API account. `}<a parentName="p" {...{
        "href": "https://dashboard.fidel.uk/sign-up"
      }}>{`Sign up`}</a>{`, if you didn't create an account yet.`}</p>
    <p>{`If you didn't create a program for your application yet, please create a Transaction Select program from your Fidel API dashboard (or via the API).`}</p>
    <h3>{`2. Integrate the iOS SDK into your project`}</h3>
    <h4>{`Using Cocoapods`}</h4>
    <blockquote>
      <p parentName="blockquote">{`Info: If you prefer `}<em parentName="p">{`not`}</em>{` to use Cocoapods, please check our instructions about integrating the SDK manually below.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Install `}<a parentName="p" {...{
            "href": "https://cocoapods.org/"
          }}>{`CocoaPods`}</a>{`, if you haven't already.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If your iOS project does not use Cocoapods (it does not contain a `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{` file), run the following command to initialize it:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`pod init
`}</code></pre>
    <ul>
      <li parentName="ul">{`Add the following line in your `}<inlineCode parentName="li">{`Podfile`}</inlineCode>{`, representing the Fidel API iOS SDK dependency that you're adding to your project:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`pod 'Fidel'
`}</code></pre>
    <p>{`Your `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{` should have a structure similar to the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ruby"
      }}>{`fileName:Podfile
platform :ios, '9.1'

target 'YouriOSTarget' do
    use_frameworks!

    pod 'Fidel'
    # ... other pods, if you're using Cocoapods already
end
`}</code></pre>
    <ul>
      <li parentName="ul">{`Run the following command to install the Fidel API iOS SDK dependency:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`pod install
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`To start working with the Fidel API iOS SDK in your project, from now on, please don't forget to open your project's `}<inlineCode parentName="p">{`.xcworkspace`}</inlineCode>{` file, not the `}<inlineCode parentName="p">{`.xcodeproj`}</inlineCode>{` file. The `}<inlineCode parentName="p">{`.xcworkspace`}</inlineCode>{` file is created by Cocoapods, when running the previous command.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the future, to update to the latest iOS SDK version, please run:`}</p>
      </li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`pod update Fidel
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`Note: For information about all the Fidel API iOS SDK versions that are used by loyalty use case applications, please check our Releases page.`}</p>
    </blockquote>
    <h4>{`Manual framework integration`}</h4>
    <blockquote>
      <p parentName="blockquote">{`Info: If you prefer to use Cocoapods, please check our instructions about integrating the SDK using Cocoapods, above.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Download the latest version of the `}<inlineCode parentName="p">{`Fidel.xcframework`}</inlineCode>{` artifact from the `}<inlineCode parentName="p">{`master`}</inlineCode>{` branch of our `}<a parentName="p" {...{
            "href": "https://github.com/Enigmatic-Smile/fidel-ios"
          }}>{`GitHub repo`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Open your iOS app project in Xcode.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Right click on your project and then on the `}<inlineCode parentName="p">{`Add Files to "YourProjectName"...`}</inlineCode>{` button.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Select the `}<inlineCode parentName="p">{`Fidel.xcframework`}</inlineCode>{` artifact. Make sure to select the `}<inlineCode parentName="p">{`Copy items if needed`}</inlineCode>{` option.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the future, to update to the latest version of our SDK, repeat the previous steps.`}</p>
      </li>
    </ul>
    <h3>{`3. Import the SDK module`}</h3>
    <p>{`In the Swift class that you want to use the SDK, please import our SDK module:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import Fidel
`}</code></pre>
    <h3>{`4. Set your SDK Key`}</h3>
    <ul>
      <li parentName="ul">{`Please `}<a parentName="li" {...{
          "href": "https://dashboard.fidel.uk/sign-in"
        }}>{`sign into`}</a>{` your Fidel API dashboard account, if you didn't already.`}</li>
      <li parentName="ul">{`Click on your account name `}<em parentName="li">{`(on the top-left hand side of the dashboard)`}</em>{` -> then on `}<inlineCode parentName="li">{`Account Settings`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Go to the `}<inlineCode parentName="li">{`Plan`}</inlineCode>{` tab and copy your `}<inlineCode parentName="li">{`Test`}</inlineCode>{` or `}<inlineCode parentName="li">{`Live`}</inlineCode>{` SDK Key.`}</li>
      <li parentName="ul">{`Set your SDK Key in your app:`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote">{`Important note: For security reasons, please DO NOT store the SDK Key in your codebase. Follow our `}<a parentName="p" {...{
          "href": "/select/sdks/security-guidelines"
        }}>{`SDK security guide`}</a>{` for detailed recommendations.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.apiKey = yourSDKKey
`}</code></pre>
    <h3>{`5. Set your Program ID`}</h3>
    <ul>
      <li parentName="ul">{`Please `}<a parentName="li" {...{
          "href": "https://dashboard.fidel.uk/sign-in"
        }}>{`sign into`}</a>{` your Fidel API dashboard account, if you didn't already.`}</li>
      <li parentName="ul">{`Go to the `}<inlineCode parentName="li">{`Programs`}</inlineCode>{` section of your Fidel API dashboard.`}</li>
      <li parentName="ul">{`Click on the `}<inlineCode parentName="li">{`Program ID`}</inlineCode>{` of the program that you want to enroll cards into. The program ID will be copied to your pasteboard.`}</li>
      <li parentName="ul">{`Set your Program ID in your app:`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.programId = "Your-Program-ID"
`}</code></pre>
    <h3>{`6. Configure the cardholder consent management feature`}</h3>
    <p>{`Asking for consent from the cardholder to enroll the card in your program is an important part of the SDK. The cardholder will need to read & agree with the conditions expressed using the consent language displayed during the card enrollment process. Making it clear for cardholders is essential.`}</p>
    <h4>{`Set your company name`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.companyName = "Your Company Name"
`}</code></pre>
    <h4>{`To support US or Canada issued cards, please set your terms and conditions`}</h4>
    <p>{`You need to set your terms and conditions URL if you would like to:
a. support all the countries that Fidel API supports
b. set a specific `}<inlineCode parentName="p">{`allowedCountries`}</inlineCode>{` set of countries AND include US or Canada in it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.termsConditionsURL = "https://yourwebsite.com/terms"
`}</code></pre>
    <h4>{`Explain how the cardholder can opt out (optional, but recommended)`}</h4>
    <p>{`Please inform the cardholder about how to opt out of transaction monitoring in your program.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.deleteInstructions = "how can the cardholder opt out"
`}</code></pre>
    <h4>{`Set your privacy policy URL (optional, but recommended)`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.privacyURL = "https://yourwebsite.com/privacy-policy"
`}</code></pre>
    <h1>{`Enrollment notifications`}</h1>
    <p>{`In order to be notified about different, useful events (a card was linked, card failed to link etc.) that happen during a enrollment process, we recommend using our webhooks.`}</p>
    <p>{`If client side notifications are useful for your application, make sure to check our SDK callback reference documentation.`}</p>
    <h1>{`Enroll a card`}</h1>
    <p>{`Call the `}<inlineCode parentName="p">{`Fidel.present`}</inlineCode>{` function to open the UI and start a card enrollment process:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Fidel.present(yourViewController, onCardLinkedCallback: { (card: LinkResult) in
    print(card.id)
}, onCardLinkFailedCallback: { (err: LinkError) in
    print(err.message)
})
`}</code></pre>
    <p>{`You can test the card enrollment flow, by setting a test SDK Key and by using the Fidel API `}<a parentName="p" {...{
        "href": "/select/cards/#test-card-numbers"
      }}>{`test card numbers`}</a>{`.`}</p>
    <p>{`If your Fidel API account is `}<inlineCode parentName="p">{`live`}</inlineCode>{` then cardholders can also enroll real, live cards. Make sure that you set a live SDK Key, in order to allow live card enrollments.`}</p>
    <h1>{`Optional: Set any of the other useful properties`}</h1>
    <p>{`Please check our SDK Reference for details about any other SDK properties that might be useful for your application.`}</p>
    <h1>{`Frequently asked questions`}</h1>
    <h3>{`How can I upgrade the iOS SDK to the latest version?`}</h3>
    <p>{`If you integrated the Fidel API iOS SDK into your project using Cocoapods, please run:`}</p>
    <p><inlineCode parentName="p">{`pod update Fidel`}</inlineCode></p>
    <p>{`from the folder where your `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{` is stored.`}</p>
    <p>{`If you integrated the SDK manually, please repeat all the steps from the manual framework integration section.`}</p>
    <h3>{`Can I customize the UI of the iOS SDK?`}</h3>
    <p>{`The iOS SDK offers the `}<inlineCode parentName="p">{`Fidel.bannerImage`}</inlineCode>{` property for you to set a custom, branded banner image that will be displayed during the card enrollment process. Please check our Reference documentation for more details.`}</p>
    <h3>{`How do I configure the consent text correctly?`}</h3>
    <p>{`In order to properly set the consent text, please follow these steps:`}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Set the company name`}</strong></li>
    </ol>
    <p>{`The `}<inlineCode parentName="p">{`Fidel.companyName`}</inlineCode>{` property is optional, but we recommended setting it. If you don't set a company name, we'll show the default value in the consent text: `}<inlineCode parentName="p">{`Your Company Name`}</inlineCode>{`.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol"><strong parentName="li">{`Set the privacy policy URL`}</strong></li>
    </ol>
    <p>{`The `}<inlineCode parentName="p">{`Fidel.privacyURL`}</inlineCode>{` property is optional. It is added as a hyperlink to the `}<inlineCode parentName="p">{`privacy policy`}</inlineCode>{` text. Please check the full behavior below.`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol"><strong parentName="li">{`Set the delete instructions`}</strong></li>
    </ol>
    <p>{`The `}<inlineCode parentName="p">{`Fidel.deleteInstructions`}</inlineCode>{` property is optional. The default value is `}<inlineCode parentName="p">{`going to your account settings`}</inlineCode>{`. This default value is applied for both consent texts that the SDK forms.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol"><strong parentName="li">{`Set the card scheme name`}</strong></li>
    </ol>
    <p>{`You can do so via the `}<inlineCode parentName="p">{`Fidel.supportedCardSchemes`}</inlineCode>{` property. By default, we allow the user to input card numbers from either Visa, Mastercard or American Express, but you can control which card networks you accept. The consent text changes based on what you define or based on what the user inputs. Please check the full behavior below.`}</p>
    <ol {...{
      "start": 5
    }}>
      <li parentName="ol"><strong parentName="li">{`Set the program name (applied to the consent text specific to the US and Canada)`}</strong></li>
    </ol>
    <p>{`The `}<inlineCode parentName="p">{`Fidel.programName`}</inlineCode>{` property is taken into account only for the consent text specific to USA and Canada. If you don't plan to support USA nor Canada, you can ignore this property. The default value for program name is `}<inlineCode parentName="p">{`our`}</inlineCode>{`.`}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol"><strong parentName="li">{`Set the terms and conditions URL (applied to the consent text only for USA and Canada)`}</strong></li>
    </ol>
    <p>{`The `}<inlineCode parentName="p">{`Fidel.termsConditionsURL`}</inlineCode>{` property is mandatory for the SDK if you plan to support USA and Canada issued cards. Once set, it will be applied as a hyperlink on the `}<inlineCode parentName="p">{`Terms and Conditions`}</inlineCode>{` text.`}</p>
    <h3>{`How is the SDK's consent text formed?`}</h3>
    <p>{`The SDK forms two distinct consent texts, depending on the country the cardholder selects as the card issuing country:`}</p>
    <ol>
      <li parentName="ol">{`A specific consent text for US and Canada`}</li>
      <li parentName="ol">{`Another consent text for the other countries supported by Fidel API.`}</li>
    </ol>
    <h4>{`Consent text for United States and Canada`}</h4>
    <p>{`You are allowing US and Canada issued cards when you:`}</p>
    <ol>
      <li parentName="ol">{`set United States and/or Canada as Fidel.`}<inlineCode parentName="li">{`allowedCountries`}</inlineCode></li>
      <li parentName="ol">{`don't set a value for the `}<inlineCode parentName="li">{`Fidel.allowedCountries`}</inlineCode>{` property, which means that all countries supported by Fidel API will be included in your SDK implementation (including US & Canada).`}</li>
    </ol>
    <p>{`For USA & Canada, the following would be an example consent text for `}<inlineCode parentName="p">{`Cashback Inc`}</inlineCode>{` (an example company) that uses `}<inlineCode parentName="p">{`Awesome Bonus`}</inlineCode>{` as their program name:`}</p>
    <p><em parentName="p">{`By submitting your card information and checking this box, you authorize `}<inlineCode parentName="em">{`card_scheme_name`}</inlineCode>{` to monitor and share transaction data with Fidel API (our service provider) to participate in `}<inlineCode parentName="em">{`Awesome Bonus`}</inlineCode>{` program. You also acknowledge and agree that Fidel API may share certain details of your qualifying transactions with `}<inlineCode parentName="em">{`Cashback Inc`}</inlineCode>{` to enable your participation in `}<inlineCode parentName="em">{`Awesome Bonus`}</inlineCode>{` program and for other purposes in accordance with the `}<inlineCode parentName="em">{`Cashback Inc`}</inlineCode>{` Terms and Conditions, `}<inlineCode parentName="em">{`Cashback Inc`}</inlineCode>{` privacy policy and Fidel’s Privacy Policy. You may opt-out of transaction monitoring on the linked card at any time by `}<inlineCode parentName="em">{`deleteInstructions`}</inlineCode>{`.`}</em></p>
    <h4>{`Consent text for the other Fidel API supported countries`}</h4>
    <p>{`When you allow countries other than US & Canada and the user selects one of these countries from the list of card issuing countries, a consent text specific for these countries will be applied.`}</p>
    <p>{`The following would be an example Terms & Conditions text for `}<inlineCode parentName="p">{`Cashback Inc`}</inlineCode>{` (an example company):`}</p>
    <p><em parentName="p">{`I authorise `}<inlineCode parentName="em">{`card_scheme_name`}</inlineCode>{` to monitor my payment card to identify transactions that qualify for a reward and for `}<inlineCode parentName="em">{`card_scheme_name`}</inlineCode>{` to share such information with `}<inlineCode parentName="em">{`Cashback Inc`}</inlineCode>{`, to enable my card linked offers and target offers that may be of interest to me. For information about `}<inlineCode parentName="em">{`Cashback Inc`}</inlineCode>{` privacy practices, please see the privacy policy. You may opt-out of transaction monitoring on the payment card you entered at any time by `}<inlineCode parentName="em">{`deleteInstructions`}</inlineCode>{`.`}</em></p>
    <h3>{`Is the SDK localized?`}</h3>
    <p>{`The SDK's default language is English, but it's also localized for French and Swedish languages. When the device has either `}<inlineCode parentName="p">{`Français (Canada)`}</inlineCode>{` or `}<inlineCode parentName="p">{`Svenska (Sverige)`}</inlineCode>{` as its language, the appropriate texts will be displayed.`}</p>
    <p>{`Please make sure that your project also supports localization for the languages that you want to support.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      